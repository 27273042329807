import toUpper from 'lodash/fp/toUpper';

import {
  INPUT_TYPES,
  INPUT_AUTOCOMPLETE,
  INPUT_COMPONENTS,
  FieldConfig,
} from './constants';
import {
  flowValidate,
  matchPattern,
  validateLength,
  validateMaxLength,
  validateMinLength,
  formatPhoneNumber,
} from './FormService';

/**
 * The field keys correspond to the options in Contentful.
 */

export const textField: FieldConfig = {
  'First name': {
    salesforceId: 'first_name',
    inputType: INPUT_TYPES.TEXT,
    autoComplete: INPUT_AUTOCOMPLETE.FIRST_NAME,
    validateFn: validateMaxLength(40),
    maxLength: 40,
    dataSelectorValue: 'first_name',
  },
  'Last name': {
    salesforceId: 'last_name',
    inputType: INPUT_TYPES.TEXT,
    autoComplete: INPUT_AUTOCOMPLETE.LAST_NAME,
    validateFn: validateMaxLength(80),
    maxLength: 80,
    dataSelectorValue: 'last_name',
  },
  'Title': {
    salesforceId: 'title',
    inputType: INPUT_TYPES.TEXT,
    validateFn: validateMaxLength(40),
    maxLength: 40,
    dataSelectorValue: 'title',
  },
  'Email': {
    salesforceId: 'email',
    inputType: INPUT_TYPES.EMAIL,
    autoComplete: INPUT_AUTOCOMPLETE.EMAIL,
    validateFn: flowValidate(
      matchPattern(/^\S+@\S+\.\S+$/i),
      validateMaxLength(80),
    ),
    maxLength: 80,
    dataSelectorValue: 'email',
  },
  'URL': {
    salesforceId: 'url',
    inputType: INPUT_TYPES.URL,
    autoComplete: INPUT_AUTOCOMPLETE.URL,
    validateFn: flowValidate(
      matchPattern(
        /^(https?:\/\/)?([0-9a-z]+\.)?[-_0-9a-z]+\.[0-9a-z]+\/?([0-9a-z/]+)?$/i,
      ),
      validateMaxLength(80),
    ),
    maxLength: 80,
    dataSelectorValue: 'url',
  },
  'Phone number': {
    salesforceId: 'phone',
    inputType: INPUT_TYPES.PHONE,
    autoComplete: INPUT_AUTOCOMPLETE.PHONE,
    validateFn: flowValidate(
      matchPattern(/^[0-9+()-\s]+$/),
      validateMaxLength(40),
      validateMinLength(7),
    ),
    formatFn: formatPhoneNumber,
    maxLength: 40,
    dataSelectorValue: 'phone',
  },
  'Mobile phone number': {
    salesforceId: '00ND0000004n9X6',
    inputType: INPUT_TYPES.PHONE,
    autoComplete: INPUT_AUTOCOMPLETE.PHONE,
    validateFn: flowValidate(
      matchPattern(/^[0-9+()-\s]+$/),
      validateMaxLength(40),
      validateMinLength(7),
    ),
    formatFn: formatPhoneNumber,
    maxLength: 40,
    dataSelectorValue: 'mobile_phone_number',
  },
  'Merchant id': {
    salesforceId: '00ND0000004pd1Q',
    inputType: INPUT_TYPES.TEXT,
    validateFn: flowValidate(matchPattern(/^[0-9A-Z]+$/), validateLength(8)),
    formatFn: toUpper,
    autoCapitalize: 'characters',
    maxLength: 8,
    dataSelectorValue: 'merchant_id',
  },
  'Company name': {
    salesforceId: 'company',
    inputType: INPUT_TYPES.TEXT,
    autoComplete: INPUT_AUTOCOMPLETE.ORGANIZATION,
    validateFn: validateMaxLength(40),
    maxLength: 40,
    dataSelectorValue: 'company',
  },
  'Message': {
    salesforceId: '00ND0000004nDd5',
    component: INPUT_COMPONENTS.TEXTAREA,
    validateFn: validateMaxLength(1000),
    maxLength: 1000,
    wrap: 'soft',
    dataSelectorValue: 'textarea',
  },
  'Billing address': {
    salesforceId: '00ND0000005nUzN',
    component: INPUT_COMPONENTS.TEXTAREA,
    dataSelectorValue: 'billing_address',
  },
  'Billing zip code': {
    salesforceId: '00ND0000005nUzX',
    inputType: INPUT_TYPES.TEXT,
    autoComplete: INPUT_AUTOCOMPLETE.POSTAL_CODE,
    validateFn: validateMaxLength(255),
    maxLength: 255,
    dataSelectorValue: 'billing_zip_code',
  },
  'Billing city': {
    salesforceId: '00ND0000005nUzc',
    inputType: INPUT_TYPES.TEXT,
    validateFn: validateMaxLength(50),
    maxLength: 50,
    dataSelectorValue: 'billing_city',
  },
  'Payment notes': {
    salesforceId: '00ND0000005nV0L',
    inputType: INPUT_TYPES.TEXT,
    validateFn: validateMaxLength(255),
    maxLength: 255,
    dataSelectorValue: 'payment_notes',
  },
  'Number of employees': {
    salesforceId: '00N57000006Mp4o',
    inputType: INPUT_TYPES.NUMBER,
    validateFn: validateMaxLength(255),
    maxLength: 255,
    dataSelectorValue: 'number_of_employees',
  },
};

export const checkboxField: FieldConfig = {
  'GDPR marketing consent given': {
    salesforceId: '00N570000030fgY',
    dataSelectorValue: 'gdpr_consent_given_checkbox',
  },
  'GDPR T&Cs accepted': {
    salesforceId: '00N570000030fgi',
    dataSelectorValue: 'gdpr_accepted_checkbox',
  },
};

export const quizField: FieldConfig = {
  'Business type': {
    salesforceId: '00ND0000005eZ7Q',
    dataSelectorValue: 'business_type',
  },
  'Platforms': {
    salesforceId: 'Which_eCom_platform_do_you_use__c',
    dataSelectorValue: 'platforms',
  },
};

export const selectField: FieldConfig = {
  'Billing country': {
    salesforceId: '00ND0000005nUzr',
    dataSelectorValue: 'billing_country',
  },
  'Language': {
    salesforceId: '00N570000066koG',
    dataSelectorValue: 'language',
  },
  'Salutation': {
    salesforceId: 'salutation',
    dataSelectorValue: 'salutation',
  },
  'Industry': {
    salesforceId: 'industry',
    dataSelectorValue: 'industry',
  },
  'MCC': {
    salesforceId: '00ND0000004n9Np',
    dataSelectorValue: 'mcc',
  },
  'Business type': {
    salesforceId: '00ND0000005eZ7Q',
    dataSelectorValue: 'business_type',
  },
  'Estimated turnover': {
    salesforceId: '00N2p000008rcfe',
    dataSelectorValue: 'estimated_turnover',
  },
  'Business focus': {
    salesforceId: 'Business_Focus__c',
    dataSelectorValue: 'business_focus',
  },
  'Partner type': {
    salesforceId: 'Partner_Type__c',
    dataSelectorValue: 'partner_type',
  },
  'Transaction Volume': {
    salesforceId: 'Average_volume_merchant_month__c',
    dataSelectorValue: 'transaction_volume',
  },
  'Platforms': {
    salesforceId: 'Which_eCom_platform_do_you_use__c',
    dataSelectorValue: 'platforms',
  },
};

export const hiddenField: FieldConfig = {
  'oid': {
    name: 'oid',
    value: '00DD0000000pnkQ',
  },
  'leadSource': {
    name: '00ND0000004n9LK',
  },
  'mandateId': {
    name: '00ND0000005eGsX',
  },
  'medium': {
    name: '00N57000006LlYR',
  },
  'source': {
    name: '00N57000006LlYW',
  },
  'campaign': {
    name: '00N57000006LlYb',
  },
  'country': {
    name: 'country',
    id: 'country',
  },
  'language': {
    name: '00N570000066koG',
  },
  'redirectUrl': {
    name: 'retURL',
  },
  'LP: Campaign': {
    name: '00N2p000008w9dO',
  },
  'Reseller': {
    name: '00N2p000008wQML',
  },
  'queryString': {
    name: '00N2p000009Sdfm',
  },
  'Prospect Product Interest': {
    name: 'Prospect_Product_interest__c',
  },
};
